<template>
  <div class="list-title">
    <div class="select-box">
      <span :class="{'active': sel == 1}" @click="sel = 1">屏蔽词标题</span>
      <span :class="{'active': sel == 2}" @click="sel = 2">高危词标题</span>
      <span :class="{'active': sel == 3}" @click="sel = 3">普通词标题</span>
    </div>
    <shield v-if="sel == 1" />
    <danger v-else-if="sel == 2" />
    <common v-else />
  </div>
</template>
<script>
import { defineComponent, ref } from "@vue/composition-api";
import shield from './components/list_titile/shield.vue'
import danger from './components/list_titile/danger.vue'
import common from './components/list_titile/common.vue'

export default defineComponent({
  components: { shield, danger, common },
  setup(prop, { root }) {
    const sel = ref(1)
    return {
      sel
    };
  },
})
</script>
<style lang="scss" scoped>
.select-box{
  display: flex;
  span{
    padding: 5px 15px;
    border: 1px solid $mainColor;
    color: $mainColor;
    cursor: pointer;
    &.active{
      color: #fff;
      background-color: $mainColor;
    }
  }
}
</style>