<template>
  <div class="table">
    <div class="search-top">
      <div>
        <span>上报时间：</span>
        <el-date-picker
          v-model="dateTime"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          placeholder="请选择时间"
        ></el-date-picker>
      </div>
      <el-button type="primary" @click="getList">查询</el-button>
      <el-button @click="clear">重置</el-button>
    </div>
    <DragTable :list-query="list" :header="header">
    </DragTable>
    <footer class="table-footer">
      <p></p>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </footer>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";

export default defineComponent({
  setup(prop, { root }) {
    const total = ref(10);
    const dateTime = ref('')
    const params = reactive({
      page: 1,
      limit: 10,
      search_start: '',
      search_end: ''
    });
    watch(dateTime,val=>{
      if(val) {
        params.search_start = root.$dayjs(val[0]).unix()
        params.search_end = root.$dayjs(val[1]).unix()
      } else {
        params.search_start = undefined
        params.search_end = undefined
      }
      getList()
    })
    const header = [
      { name: "站点", value: "site_name" },
      { name: "商品ID", value: "goods_id" },
      { name: "商品标题", value: "title" },
      { name: "展现次数", value: "total" },
    ];
    const list = reactive({ data: [] });
    const getList = () => {
      root.$axios
        .get("/rcp/goodsManage/goods/title/listStatistic", {
          params,
        })
        .then((res) => {
          list.data = res.data.list;
          total.value = res.data.total;
        });
    };
    const clear = () => {
      for (let key in params) {
        params[key] = undefined;
      }
      params.page = 1;
      params.limit = 10;
      dateTime.value = undefined
      getList();
    };
    const handleSizeChange = (size) => {
      params.limit = size;
      params.page = 1
      getList();
    };
    const handleCurrentChange = (index) => {
      params.page = index;
      getList();
    };
    getList();
    return {
      dateTime,
      total,
      params,
      list,
      header,
      clear,
      getList,
      handleSizeChange,
      handleCurrentChange,
    };
  },
})
</script>
<style lang="scss" scoped>
.table {
  width: 100%;
  // display: flex;
  // flex-direction: column;
}
.search-top {
  display: flex;
  margin-top: 15px;
  align-items: center;
  margin-bottom: 15px;
  & > div {
    display: flex;
    align-items: center;
    margin-right: 15px;
    span {
      font-size: 14px;
      white-space: nowrap;
      text-align: right;
      flex-shrink: 0;
    }
    &:nth-of-type(2) {
      span {
        width: 70px;
      }
    }
    &:nth-of-type(3) {
      .input {
        width: 350px;
      }
    }
  }
}
.status {
  color: $safeColor;
  position: relative;
  padding-left: 13px;
  &.red {
    color: $dangerColor;
  }
}
.control {
  display: flex;
  justify-content: center;
  span {
    margin-right: 14px;
    cursor: pointer;
    font-size: 14px;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
    &.red {
      color: $dangerColor;
    }
    &.green {
      color: $safeColor;
    }
  }
}
.table-img {
  width: 100%;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>